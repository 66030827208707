<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SENIOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="seniorClassCd"
            label="구분"
            v-model="searchParam.seniorClassCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="선임명 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="seniorId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "senior-dismissal-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        seniorClassCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: "seniorClassName",
            field: "seniorClassName",
            label: "구분",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:250px',
            align: 'center',
            sortable: true,
          },
          {
            name: "seniorName",
            field: "seniorName",
            label: "선임명",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "qualifiyRequire",
            field: "qualifiyRequire",
            label: "자격요건",
            align: "left",
            style: 'width:250px',
            sortable: true,
          },
          {
            name: "seniorStandard",
            field: "seniorStandard",
            label: "선임기준",
            align: "left",
            style: 'width:250px',
            sortable: true,
          },
          {
            name: "reportAppointName",
            field: "reportAppointName",
            label: "신고/선임",
            align: "center",
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 150);
      if (height < 400) {
        height = 400;
      }
      this.grid.height = height + 'px'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.senior.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선임명을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
